// unfetch is a tiny 500b fetch polyfill
// more info - https://www.npmjs.com/package/unfetch
import { addQueryParam } from '@/utils/url';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import 'unfetch/polyfill';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        imported_git_repository: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ImportedGitRepositoryType',
              id: args.id,
            });
          },
        },
        range_model_metrics: {
          // This allows different model metrics to be stored together in the cache
          merge: true,
        },
        instant_model_metrics: {
          // This allows different model metrics to be stored together in the cache
          merge: true,
        },
      },
    },
    PretrainedModelType: {
      keyFields: ['name'],
    },
    OrganizationType: {
      keyFields: ['name'],
    },
  },
});

const apolloClient = new ApolloClient({
  link: new HttpLink({
    // The opName parameter isn't needed for the API to function properly, but it's useful for debugging via Chrome devtools
    uri: (op) => addQueryParam('/graphql/', 'opName', op.operationName),
  }),
  cache,
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

export { cache };
export default apolloClient;
